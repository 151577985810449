<template>
  <div>
    <div class="row page-title">
      <label class="Agency-Name-HFM-Co">
        {{ Request.VendorBusinessName}} </label>
    </div>
    <div class="row icreview-component">
      <prodexp-amend-overview class="col-lg-8" :ID="id" v-on:LoadProdEXPAmendDetails="LoadVendorRequestDetails"></prodexp-amend-overview>
      <div class="col-lg-4" v-if="IsDataLoaded">
        <div class="py-2 px-1">
          <b-alert :show="!IsApproveOrRejectEnabled" class="alert-info-new position-fixed fixed-top m-0 rounded-0" variant="info" dismissible>
              The Request is already approved or rejected.
          </b-alert>
          <p class="approve-reject-label px-3 pt-2 pb-1">
            Approve or Reject the Amend Production / Expense Vendor
          </p>
          <p class="approve-reject-content px-3 py-2">
            When approved, this request will be sent to the Vendor Validation Team.
          </p>
          <div class="text-right pb-3 pr-3" style="width: 100%">
            <button v-if="IsApproveOrRejectEnabled" class="btn custom-button btn-primary" @click="Approve()">
              Approve
            </button>
            <button v-else class="btn custom-button btn-primary" disabled="disabled"
              title="The request is already approved or rejected.">
              Approve
            </button>
          </div>
        </div>
        <div class="py-2 my-2 px-3">
          <label class="">Rejection Comments</label>
          <b-textarea class="rejection-comments-new m-custom" placeholder="Enter Rejection Comments" v-model="Comments" v-bind:class="{
            errorborder: displaycommentserror && showCommentsError,
            noterrorborder: !displaycommentserror || !showCommentsError,
          }" @blur="SetDirty()" @focus="ResetDirty()" rows="5">
          </b-textarea>
          <div class="error-message-format required-field" v-if="displaycommentserror && showCommentsError">
            Required field
          </div>
          <div class="text-right" style="width: 100%; padding-top: 10px">
            <button v-if="IsApproveOrRejectEnabled" class="btn custom-button btn-outline-primary" @click="Reject()">
              Reject
            </button>
            <button v-else class="btn custom-button btn-outline-primary" disabled="disabled"
              title="The request is already approved or rejected.">
              Reject
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import { required } from "vuelidate/lib/validators";
import * as status from "../../../utilities/VendorWorkflowStates";

export default {
  name: 'ApprovalLevel2',
  validations: {
    Comments: {
      required,
    },
  },
  data() {
    return {
      displaycommentserror: false,
      Request: {},
      Comments: "",
      showCommentsError: false,
    };
  },
  props: ["id"],
  mounted() {
    if (!this.canApprovePendingFinanceDirector) {
      this.$router.push({
        name: "error",
        params: { message: "UnAuthorized" },
      });
    }
  },
  computed: {
    IsApproveOrRejectEnabled(){
      return this.Request && this.Request.Status == status.FinanceDirectorApproval;
    },
    IsDataLoaded() {
      return this.Request && !(this.Request.Status == "" || this.Request.Status == null);
    },
    canApprovePendingFinanceDirector() {
      return this.$store.getters.canApprovePendingFinanceDirectorVendorRequest;
    }
  },
  methods: {
    LoadVendorRequestDetails(vendorRequestDetails){
      this.Request = vendorRequestDetails;
    },
    SetDirty() {
      if (this.Comments.length > 0) {
        this.showCommentsError = false;
      } else {
        this.showCommentsError = true;
      }
    },
    ResetDirty() {
      this.showCommentsError = false;
    },
    GetData() {
      return {
        ID: parseInt(this.id),
        Comments: this.Comments,
        ActiveTaskID: this.Request.ActiveTaskID,
        ProjectID: this.Request.OmniflowProjectID,
        ProcessID: this.Request.OmniflowProcessID,
        Requestor: this.Request.Requestor,
      };
    },
    Approve() {
      this.$store
        .dispatch("prodexpamend/ApproveRequestLevel2", this.GetData())
        .then(() => {
          store.dispatch(
            "progress/setSuccessMessage",
            `The request ${this.Request.VendorBusinessName} has been approved`
          );
          this.$router.push("/VendorRequests");
        })
        .catch((ex) => {
          if (ex.response) {
            this.SetErrorMessage(
              "Error while approving request",
              ex.response.data
            );
          } else {
            this.SetErrorMessage("Error while approving request", ex);
          }
        });
    },

    Reject() {
      if (this.Comments.length > 0) {
        this.displaycommentserror = false;
        this.$store
          .dispatch("prodexpamend/RejectRequestLevel2", this.GetData())
          .then(() => {
            store.dispatch(
              "progress/setSuccessMessage",
              `Request ${this.Request.VendorBusinessName} has been rejected`
            );
            this.$router.push("/VendorRequests");
          })
          .catch((ex) => {
            window.console.log(JSON.stringify(ex.response));
            if (ex.response) {
              this.SetErrorMessage(
                "Error while rejecting request",
                ex.response.data
              );
            } else {
              this.SetErrorMessage("Error while rejecting request", ex);
            }
          });
      } else {
        this.displaycommentserror = true;
        this.showCommentsError = true;
      }
    },
    SetErrorMessage(message, ex) {
      store.dispatch(
        "progress/setErrorMessage",
        `${message} ${this.Request.VendorBusinessName}. The error is ${ex}`
      );
    },
  },
};
</script>